import { setLivePosition, setNewPosition, setPausePosition } from "actions/ui";
import Button, { ButtonType } from "components/Button";
import BackArrow from "components/Icons/Arrowheading";
import CatchupIcon from "components/Icons/CatchUp";
import CloseIcon from "components/Icons/Close";
import PauseIcon from "components/Icons/Pause";
import PlayIcon from "components/Icons/Play";
import PlayerCloseFullscreen from "components/Icons/PlayerCloseFullscreen";
import PlayerOpenFullscreen from "components/Icons/PlayerOpenFullscreen";
import RemoteIcon from "components/Icons/Remote";
import SeekBackwardIcon from "components/Icons/SeekBackward";
import SeekForwardIcon from "components/Icons/SeekForward";
import SkipAd from "components/Icons/SkipAd";
import Image, { PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import ModalNPVRRecord from "components/ModalNPVRRecord";
import PlayerAdditionalModal from "components/PlayerAdditionalModal";
import PlayerRemote from "components/PlayerRemote";
import PlayerSubtitleControler from "components/PlayerSubtitleControler";
import PlayerTVShowNavigator from "components/PlayerTVShowNavigator";
import PlayerVolumeControler from "components/PlayerVolumeControler";
import ProgressBarLive from "components/ProgressBarLive";
import RecordActions from "components/RecordActions";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { isLiveOrUpComing } from "helpers/time";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { compose } from "redux";

import style from "./style.module.css";

class PlayerOverlayLive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNextProgramInfoVisible: true,
      isNextEpisodeInfoVisible: true,
      draggable: false,
      startoverClicked: false,
      activeEpg: null,
      showSubtitleController: false,
    };
  }

  handleClickBack(event) {
    event.preventDefault();
    this.props.handleclickonback();
  }

  hideAllController = () => {
    if (this.props.isAdditionalContentVisible) {
      this.props.handleCloseRemote();
    }
    this.setState({
      showSubtitleController: false,
    });
  };

  setShowSubtitleController = (value) => {
    if (this.props.isAdditionalContentVisible) {
      this.props.handleCloseRemote();
    }
    this.setState({ showSubtitleController: value });
  };

  handleClickOverlay() {
    if (
      !this.state.showSubtitleController &&
      !this.props.isAdditionalContentVisible
    ) {
      return this.props.setIsOverlayVisibleToFalse();
    } else if (this.state.showSubtitleController) {
      return this.setState({
        showSubtitleController: false,
      });
    } else if (this.props.isAdditionalContentVisible) {
      return this.props.handleCloseRemote();
    }

    if (this.props.config.canPause === false || this.props.timeshift < 0) {
      return;
    }
  }

  async handleClickOnPlayPause(e) {
    e.stopPropagation();
    const { info, isPlaying, pausePosition, newPosition, livePosition } =
      this.props;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const currentPosition =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    let pos = newPosition - (currentPosition - pausePosition);
    pos = pos < 0 ? 0 : pos;

    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }

    if (isPlaying) {
      await this.props.dispatch(setPausePosition(currentPosition));
      if (!livePosition)
        await this.props.dispatch(setLivePosition(currentPosition));
      await this.setState({ draggable: true });
    } else {
      if (pos > 0) {
        await this.props.dispatch(setNewPosition(pos));
        await this.props.dispatch(setPausePosition(null));
      }
    }
    if (isPlaying || pos >= 0) {
      this.props.onClickPlayPause();
    }
  }

  handleClickOnSeekBackward = async (e) => {
    //if (this.props.config.canSeekBackward) {
    //return;
    //}
    e.stopPropagation();
    const { newPosition, info } = this.props;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const calculatedPosition = newPosition - (15000 / duration) * 100;
    await this.props.dispatch(setNewPosition(calculatedPosition));
    // console.log("calculated", calculatedPosition);
    const position =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    await this.props.dispatch(setLivePosition(position));
    if (calculatedPosition + 1 !== 0) await this.setState({ draggable: true });
    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }
    this.props.onClickSeekBackward();
  };

  handleClickOnSeekForward = (e) => {
    //if (this.props.config.canSeekForward) {
    //return;
    //}
    const { newPosition, info } = this.props;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const calculatedPosition = newPosition + (15000 / duration) * 100;
    const currentPosition =
      ((new Date().getTime() - new Date(info.startTime).getTime()) /
        (new Date(info.endTime).getTime() -
          new Date(info.startTime).getTime())) *
      100;
    this.props.dispatch(setNewPosition(calculatedPosition));
    if (calculatedPosition + 1 < currentPosition)
      this.setState({ draggable: true });
    e.stopPropagation();
    if (
      this.state.showSubtitleController ||
      this.props.isAdditionalContentVisible
    ) {
      this.hideAllController();
    }
    this.props.onClickSeekForward();
  };

  handleClickOnFullscreen(e) {
    e.stopPropagation();
    if (this.props.isAdditionalContentVisible) this.props.onClickRemote();
    if (this.state.showSubtitleController)
      this.setShowSubtitleController(false);
    this.props.onClickFullscreen();
  }

  handleClickOnRemote(e) {
    e.stopPropagation();
    this.setShowSubtitleController(false);
    this.props.onClickRemote();
  }

  handleClickOnCloseNextProgram(e) {
    e.stopPropagation();
    this.setState({ isNextProgramInfoVisible: false });
  }

  handleStartoverClick = async (_e) => {
    // e.stopPropagation();
    const { info, onClickProgressBarLive, newPosition } = this.props;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const position =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    const calculatedPosition = newPosition - newPosition;
    // await this.props.dispatch(setPausePosition(position));
    await this.props.dispatch(setLivePosition(position));
    await this.props.dispatch(setNewPosition(calculatedPosition));
    this.setState({ draggable: true, startoverClicked: true });
    onClickProgressBarLive(calculatedPosition, true, position);
  };
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  async componentDidMount() {
    const { info, toRestartLive } = this.props;
    saveInLocal("displaycontrols", true);
    const currentPosition =
      ((new Date().getTime() - new Date(info.startTime).getTime()) /
        (new Date(info.endTime).getTime() -
          new Date(info.startTime).getTime())) *
      100;

    await this.props.dispatch(setNewPosition(currentPosition));
    if (toRestartLive) {
      const timeInterval = setInterval(async () => {
        // This check to insure that the player have the buffer to go back
        const timeInfo = await window?.player?.currentPlayer?.getTimeInfo();
        if (timeInfo && timeInfo.buffers?.length > 0) {
          clearInterval(timeInterval);
          await this.handleStartoverClick();
        }
      }, 500);
    }
  }

  render() {
    const {
      isConnected,
      isVisible,
      isPlaying,
      isFullscreen,
      config,
      info,
      liveEPG,
      channels,
      onClickProgressBarLive,
      onChangeVolume,
      isAdditionalContentVisible,
      height,
      width,
      viewport,
      onChangeTrack,
      timeshift,
      newPosition,
      showLoader,
      showPauseIcon,
      epg,
      modalNPVRInfo,
      hideControls,
      showSkipAdButton,
      onSkipAdClick,
      t,
    } = this.props;
    const { isNextProgramInfoVisible, showSubtitleController } = this.state;
    const duration =
      new Date(info.endTime).getTime() - new Date(info.startTime).getTime();
    const currentPosition =
      ((new Date().getTime() - new Date(info.startTime).getTime()) / duration) *
      100;
    const displayIcons = getFromLocal("displaycontrols");

    const now = new Date().getTime();
    let isStartoverAllowed = false,
      isCurrentProgram = false;
    if (info && info.startTime && info.endTime) {
      isStartoverAllowed =
        now - info.startTime.getTime() < info.channel.timeshift;
      isCurrentProgram =
        now > info.startTime.getTime() && now < info.endTime.getTime();
    }

    return (
      <CSSTransition
        in={isVisible}
        timeout={1000}
        classNames={{
          enter: style.overlayEnter,
          enterActive: style.overlayEnterActive,
          enterDone: style.overlayEnterDone,
          exit: style.overlayExit,
          exitActive: style.overlayExitActive,
          exitDone: style.overlayExitDone,
        }}
      >
        <Fragment>
          {modalNPVRInfo?.showModalNPVR && <ModalNPVRRecord />}
          <div
            className={style.rootContainer}
            onClick={this.handleClickOverlay.bind(this)}
          >
            <div className={style.headerContainer}>
              <BackArrow
                className={style.headerBack}
                onClick={this.handleClickBack.bind(this)}
              />
              {info.channel && (
                <div className={style.headerLogo}>
                  <Image
                    src={info.channel.getImage(consts.image_hostname, {
                      width: 171,
                      height: 171,
                      languageId: getFromLocal("languageId"),
                    })}
                    alt="Channel logo"
                    placeholder={PlaceholderType.TRANSPARENT}
                  />
                </div>
              )}
              <div className={style.headerTexts}>
                {info.title && (
                  <p className={style.headerTitle}>{info.title}</p>
                )}
                {info.subtitle && (
                  <p className={style.headerSubtitle}>{info.subtitle} </p>
                )}
                {info.rating && (
                  <p className={style.headerRating}> | {info.rating}</p>
                )}
              </div>
            </div>
            <div className={style.centerIconsContainer}>
              {showLoader && <Loader centered={true} />}
              {!showLoader && (
                <>
                  {config.canSeekBackward &&
                    timeshift &&
                    timeshift > 0 &&
                    info &&
                    info.startTime &&
                    info.endTime &&
                    displayIcons &&
                    !hideControls && (
                      <>
                        {newPosition - (15000 / duration) * 100 > 0 ? (
                          <SeekBackwardIcon
                            className={style.fastSeek}
                            onClick={(e) => this.handleClickOnSeekBackward(e)}
                          />
                        ) : (
                          <SeekBackwardIcon className={style.fastSeekGray} />
                        )}
                      </>
                    )}
                  {((config.canPause &&
                    timeshift &&
                    timeshift > 0 &&
                    displayIcons) ||
                    showPauseIcon) &&
                    !hideControls && (
                      <div
                        className={style.playIconContainer}
                        onClick={this.handleClickOnPlayPause.bind(this)}
                      >
                        {((!isPlaying &&
                          info &&
                          info.startTime &&
                          info.endTime) ||
                          showPauseIcon) && (
                          <PlayIcon className={style.playIcon} />
                        )}
                        {isPlaying &&
                          info &&
                          info.startTime &&
                          info.endTime &&
                          !showPauseIcon && (
                            <PauseIcon className={style.playIcon} />
                          )}
                      </div>
                    )}
                  {config.canSeekForward &&
                    timeshift &&
                    timeshift > 0 &&
                    info &&
                    info.startTime &&
                    info.endTime &&
                    displayIcons &&
                    !hideControls && (
                      <>
                        {newPosition + (15000 / duration) * 100 <
                        currentPosition ? (
                          <SeekForwardIcon
                            className={style.fastSeek}
                            onClick={(e) => this.handleClickOnSeekForward(e)}
                          />
                        ) : (
                          <SeekForwardIcon className={style.fastSeekGray} />
                        )}
                      </>
                    )}
                </>
              )}
            </div>
            {showSkipAdButton && (
              <Button
                rootClassName={style.skipAdButton}
                type={ButtonType.NORMAL}
                onClick={onSkipAdClick}
              >
                <>
                  {t("Skip Ad")} <SkipAd className={style.skipAdIcon} />
                </>
              </Button>
            )}
            <RemoteIcon
              className={style.remoteIcon}
              onClick={this.handleClickOnRemote.bind(this)}
            ></RemoteIcon>
            {info && info.startTime && info.endTime && !hideControls && (
              <ProgressBarLive
                rootClassName={style.progressBar}
                onClick={(value, submitSeek, current) => {
                  this.setState({ draggable: true });
                  onClickProgressBarLive(value, submitSeek, current);
                }}
                info={info}
                timeshift={timeshift}
                draggable={this.state.draggable}
                position={
                  ((new Date().getTime() - new Date(info.startTime).getTime()) /
                    duration) *
                  100
                }
                duration={duration}
                onClickBackwards={(e) => this.handleClickOnSeekBackward(e)}
                onClickForward={(e) => this.handleClickOnSeekForward(e)}
                startoverClicked={this.state.startoverClicked}
              />
            )}
            <div className={style.bottomContainer}>
              <PlayerVolumeControler
                setVolume={onChangeVolume.bind(this)}
                currentVolume={info.volume}
                setShowSubtitleController={this.setShowSubtitleController.bind(
                  this
                )}
                viewport={viewport}
              />
              {(info?.tracks?.text?.length > 0 ||
                info?.tracks?.audio?.length > 0) && (
                <PlayerSubtitleControler
                  setShowSubtitleController={this.setShowSubtitleController}
                  showSubtitleController={showSubtitleController}
                  hide={isVisible}
                  tracks={info.tracks}
                  handleTrackChange={onChangeTrack.bind(this)}
                />
              )}
              {isFullscreen && (
                <PlayerCloseFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
              {!isFullscreen && (
                <PlayerOpenFullscreen
                  className={style.bottomIcon}
                  onClick={this.handleClickOnFullscreen.bind(this)}
                />
              )}
            </div>
            <div className={style.bottomContainerLeft}>
              {info.channel?.startOver &&
                isStartoverAllowed &&
                isCurrentProgram &&
                info.startoverEnabled &&
                !hideControls && (
                  <CatchupIcon
                    className={style.catchupIcon}
                    onClick={this.handleStartoverClick}
                  />
                )}
            </div>
            <div className={style.buttonNPVRRecord}>
              {isLiveOrUpComing(
                epg?.getStartTime().getTime(),
                epg?.getEndTime().getTime()
              ) &&
                info?.channel.npvr >= -1 &&
                epg.npvrEnabled && (
                  <RecordActions
                    type={epg.getRecordStatus()}
                    epg={epg}
                    channel={info?.channel}
                    typePage={"playerLive"}
                  />
                )}
            </div>
            {isNextProgramInfoVisible && info.nextEPG && info.nextEPG.title && (
              <div className={style.nextEPGContainer}>
                <Image
                  rootClassName={style.nextEPG}
                  width={width}
                  height={height}
                  src={info.nextEPG.imageUrl}
                  placeholder={PlaceholderType.PRIMARY}
                />
                <div className={style.nextEPGInfoContainer}>
                  <div>
                    {info.nextEPG.timeslot && (
                      <p className={style.nextEPGTimeslot}>
                        {info.nextEPG.timeslot}
                      </p>
                    )}
                    <p className={style.nextEPGTitle}>{info.nextEPG.title}</p>
                  </div>
                  <CloseIcon
                    className={style.nextEPGIcon}
                    onClick={this.handleClickOnCloseNextProgram.bind(this)}
                  />
                </div>
              </div>
            )}
          </div>
          {isAdditionalContentVisible && (
            <PlayerAdditionalModal>
              <PlayerRemote
                isConnected={isConnected}
                liveEPG={liveEPG}
                channels={channels}
                currentEPG={info}
                isFullscreen={isFullscreen}
              />
              {info.isTVShowEpisode && info.seasons && (
                <PlayerTVShowNavigator
                  assetId={info.assetId}
                  seasons={info.seasons}
                  activeSeason={info.activeSeason}
                  activeEpisode={info.activeEpisode}
                  subscriptionModal={this.subscriptionModal.bind(this)}
                  isFullscreen={isFullscreen}
                  isConnected={isConnected}
                />
              )}
            </PlayerAdditionalModal>
          )}
        </Fragment>
      </CSSTransition>
    );
  }
}
export default compose(
  connect((state) => {
    return {
      newPosition: state.ui.newPosition,
      pausePosition: state.ui.pausePosition,
      livePosition: state.ui.livePosition,
      toRestartLive: state.player.toRestartLive,
      modalNPVRInfo: state.ui.modalNPVRInfo,
    };
  })
)(PlayerOverlayLive);
